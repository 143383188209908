/* General */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.page-container {
  margin-top: 50px;
}

button {
  background-color: var(--white) !important;
}

h1 {
  margin-bottom: 0;
}

p {
  font-size: 12px;
  font-family: "Poppins";
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.navbar {
  background-color: var(--orange);
  color: var(--white);
}

.navbar-brand h1 {
  color: var(--white);
  font-family: var(--proffesional-font);
  font-size: 28px;
  font-weight: 700;
  margin-right: 56px;
}

.nav-link {
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
  align-self: flex-end;
  margin-left: 34px;
  margin-right: 34px;
}

.nav-link.active {
  color: var(--white) !important;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .nav-link {
    align-self: center;
  }
}

/* Home Page */
.wacky-button {
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.welcome-button {
  background-size: 300px;
  left: 100px;
  width: 300px;
  height: 120px;
  z-index: 2;
}

.welcome-button-text {
  color: var(--white);
  font-family: var(--proffesional-font);
  font-size: 36px;
  font-weight: 700;
  margin-top: 15px;
}

.about-button {
  background-size: 200px;
  width: 200px;
  height: 120px;
}

.about-button-text {
  color: var(--white);
  font-family: var(--wacky-font);
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}

.portfolio-button {
  background-size: 200px;
  width: 200px;
  height: 120px;
}

.portfolio-button-text {
  color: var(--white);
  font-family: var(--artsy-font);
  font-size: 28px;
  font-weight: 400;
}

.home-title {
  color: var(--blue);
  font-family: var(--proffesional-font);
  font-size: 48px;
  font-weight: 700;
}

.home-subtitle {
  color: var(--orange);
  font-family: var(--proffesional-font);
  font-size: 24px;
  font-weight: 700;
}
/* About Page */
.about-title {
  color: var(--blue);
  font-family: var(--wacky-font);
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 30px;
}

.darby-pic {
  padding: 20px;
}

.darby-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-resume-link {
  text-decoration: none;
}

.download-resume-button {
  background-size: 160px;
  width: 160px;
  display: flex;
  height: 85px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.download-resume-button-text {
  color: var(--white);
  font-family: var(--artsy-font);
  font-size: 18px;
  font-weight: 400;
  margin-left: -25px;
  margin-top: 15px;
  max-width: 150px;
  text-align: right;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
}

.lets-chat-button {
  background-size: 170px;
  width: 170px;
  height: 72px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.lets-chat-button-text {
  color: var(--white);
  font-family: var(--artsy-font);
  font-size: 20px;
  font-weight: 400;
  margin-left: -30px;
  margin-top: 15px;
}

.lets-chat-button-chevron {
  position: absolute;
  right: 20px;
}

@media (max-width: 767px) {
  .darby-pic {
    margin-top: 75px;
    max-width: 70%;
  }
}

@media (max-width: 367px) {
  .about-title {
    font-size: 36px;
  }
  .darby-pic {
    margin-top: 75px;
    max-width: 100%;
  }
}

/* Contact Page */

.contact-icon {
  height: 80px;
  width: 80px;
}

.contact-text {
  text-align: center;
}

.contact-text-container {
  align-items: flex-start;
  padding-top: 15%;
}

.linked-in-text {
  color: var(--blue);
  font-family: var(--lilitia-font);
  font-size: 48px;
}

.linked-in-button {
  background-size: 406px;
  width: 406px;
  height: 348px;
  align-self: center;
}

.linked-in-button-text {
  color: var(--white);
  font-family: var(--lilitia-font);
  font-size: 48px;
  font-weight: 400;
  margin-left: -50px;
  margin-top: 80px;
  text-decoration: underline;
}

.linked-in-button-icon {
  position: absolute;
  left: 129px;
  top: 86px;
}

.twitter-text {
  color: var(--blue);
  font-family: var(--artsy-font);
  font-size: 48px;
}

.twitter-button {
  background-size: 286px;
  width: 286px;
  height: 205px;
  margin: auto;
  align-self: center;
}

.twitter-button-text {
  color: var(--white);
  font-family: var(--artsy-font);
  font-size: 48px;
  margin-left: -20px;
  margin-top: 40px;
  text-decoration: underline;
}

.twitter-button-icon {
  position: absolute;
  top: 40px;
  right: 67px;
}

.email-text {
  color: var(--blue);
  font-family: var(--wacky-font);
  font-size: 48px;
}

.email-button {
  background-size: 582px;
  width: 582px;
  height: 330px;
  margin-left: -250px;
  align-self: center;
}

.email-button-text {
  color: var(--white);
  font-family: var(--wacky-font);
  font-size: 36px;
  font-weight: 400;
  margin-top: 15px;
  position: absolute;
  left: 100px;
  bottom: 120px;
}

.email-button-email {
  color: var(--white);
  font-family: var(--proffesional-font);
  font-size: 36px;
  position: absolute;
  bottom: 77px;
  left: 100px;
}

.background-cover {
  background-color: rgba(0, 0, 0, 30%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: none;
}
.wacky-contact-button-container {
  z-index: 2;
}

@media (max-width: 1399px) {
  .email-button {
    margin-left: -300px;
  }
}

@media (max-width: 1199px) {
  .email-button {
    margin-left: -350px;
  }
}

@media (max-width: 767px) {
  .wacky-contact-button-container {
    position: absolute;
    left: 170px;
    bottom: -250px;
  }
  .email-button {
    margin-left: -150px;
    transform: scale(0.8);
  }
  .linked-in-button {
    margin-left: -50px;
    transform: scale(0.8);
  }
}

@media (max-width: 575px) {
  .wacky-contact-button-container {
    left: 0px;
  }
  .email-button {
    margin-left: -120px;
    transform: scale(0.6);
  }
  .linked-in-button {
    transform: scale(0.6);
  }
}

@media (max-width: 375px) {
  .wacky-contact-button-container {
    left: 0px;
  }
  .email-button {
    margin-left: -150px;
    transform: scale(0.5);
  }

  .twitter-button {
    transform: scale(0.8);
  }
}

/* Portfolio Page */
.splash-button {
  margin: 35px auto 35px auto;
}
