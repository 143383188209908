:root {
  --blue: #4152a4;
  --orange: #f36648;
  --white: #fffcf4;
  --proffesional-font: "Lantinghei SC", sans-serif;
  --wacky-font: "Lemon", sans-serif;
  --artsy-font: "Marko One", sans-serif;
  --lilitia-font: "Lilita One", sans-serif;
}

body {
  background-color: var(--white);
  margin: 0;
  font-family: -apple-system, Poppins, "Lantinghei SC", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
  height: 100%;
}
